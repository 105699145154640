///*------------------------------------*\
//    # MATH
//\*------------------------------------*/

// Halve and double numbers, returning rounded integers. E.g.:
//
// .foo {
//     padding: double($gel-spacing-unit);
// }
//
// @param {Int}     $gel-number - The initial integer you wish to apply
//                                the math logic to
//
// @return {Int}    The updated integer
//
// @author          Harry Roberts - http://bit.ly/1NTThPq
//
@function quarter($gel-number) {
    @return round($gel-number / 4);
}

@function third($gel-number) {
    @return round($gel-number / 3);
}

@function halve($gel-number) {
    @return round($gel-number / 2);
}

@function double($gel-number) {
    @return round($gel-number * 2);
}

@function triple($gel-number) {
    @return round($gel-number * 3);
}

@function quadruple($gel-number) {
    @return round($gel-number * 4);
}
