/// EXAMPLE

// @font-face {
//   font-family: 'Canela Web';
//   src: url('../fonts/commercial-type-1808-VHLYCU-web/Canela-Thin-Web.eot');
//   src: url('../fonts/commercial-type-1808-VHLYCU-web/Canela-Thin-Web.eot?#iefix') format('embedded-opentype'),
//        url('../fonts/commercial-type-1808-VHLYCU-web/Canela-Thin-Web.woff2') format('woff2'),
//        url('../fonts/commercial-type-1808-VHLYCU-web/Canela-Thin-Web.woff') format('woff');
//   font-weight:  100;
//   font-style:   normal;
//   font-stretch: normal;
// }

// .Canela-Thin-Web {
//   font-family: 'Canela Web';
//   font-weight:  100;
//   font-style:   normal;
//   font-stretch: normal;
// }

@font-face {
	font-family:'gilroy-regular';
	src: url('../fonts/gilroy-regular-webfont.woff2');
	src: url('../fonts/gilroy-regular-webfont.woff2') format('woff2'),
		url('../fonts/gilroy-regular-webfont.woff') format('woff');
}

@font-face {
	font-family:'gilroy-semibold';
	src: url('../fonts/gilroy-semibold-webfont.woff2');
	src: url('../fonts/gilroy-semibold-webfont.woff2') format('woff2'),
		url('../fonts/gilroy-semibold-webfont.woff') format('woff');
}

// gilroy-regular-webfont.woff
// gilroy-semibold-webfont.woff

// .Gilroy-Medium {
//   font-family: 'Gilroy-Medium';
//   font-weight:  300;
//   font-style:   normal;
//   font-stretch: normal;
// }