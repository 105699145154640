// @include gel-wrap;
// @include gel-layout;
// @include gel-layout-item;
// @include gel-columns(6/12);
// @include mq($from: tablet) {
// @include mq($from: desktop) {
// @include mq($from: wide) {

* {
    box-sizing: border-box;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}


// STICKY FOOTER WITH FLEX
html,body { height: 100%; }
body {
  display: flex;
  flex-direction: column;
}
.main-content { flex: 1 0 auto; }
.site-footer { flex-shrink: 0; }


html {
    
}

body {
    // transition: all .4s .3s ease-in;
}

// this needs to go elsewhere - remove outline unless using keyboard.
:focus:not(:focus-visible) { outline: none }

.main-content {

    padding-top: $mobileNavHeight;
    @include mq($from: tablet) { padding-top: $tabletNavHeight; }
    @include mq($from: desktop) { padding-top: $desktopNavHeight; }

    header,
    .entry-content,
    .entry-footer,
    .share-bar,
    .post-navigation,
    .comments,
    .post-comments {
        // @include gel-wrap;
    }

    .entry-content--content {
        // @include gel-layout;
        > * {
            // @include gel-layout-item;
        }
    }

    // > * {
    //     // @include gel-wrap;
    // }

    // @include gel-wrap;
    // @include gel-layout;

    article {
        // @include gel-layout-item;
        @include mq($from: tablet) {
            // padding: 0;
            // @include gel-columns(10/12);
            // margin-left: gel-columns(1/12);
        }
        @include mq($from: desktop) {
            // @include gel-columns(8/12);
            // margin-left: gel-columns(2/12);
        }
    }
}