@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateX(-15px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    // transform: translateX(-15px);
  }
  to {
    opacity: 1;
    // transform: translateX(0);
  }
}

@keyframes grow-left {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

// @include animate-keyframe(fadeIn) {
//   0% {opacity: 0;}
//   100% {opacity: 1;}
// }

.fadeIn {
  // @include animate-prefixer(animation-name, fadeIn);
  opacity: 0;
  animation: fade-in ease 0.3s forwards;
}


html.js .lazyload {
  opacity: 0;
}
html.js .lazyloading {
  opacity: 0;
}
.lazyloaded:not(.slick-slide) {
  //added anim
  opacity: 0;
  animation: fade-in ease 0.3s forwards;
  // animation-delay: 0.4s;
}

.lazy-bg-image-lazyloaded {
  opacity: 0;
  animation: fade-in ease 0.15s forwards;
}

html.js .wow {
  visibility: hidden;
}

// img {
//   //added anim
//   opacity: 0;
//   animation: fade-in ease 0.4s forwards;
//   animation-delay: 0.8s;
// }


// .home-hero--content {
//   opacity: 0;
//   animation: fade-in ease 0.6s forwards;
//   animation-delay: 1.2s;
// }