// @include gel-wrap;
// @include gel-layout;
// @include gel-layout-item;
// @include gel-columns(6/12);
// @include mq($from: tablet) {
// @include mq($from: desktop) {
// @include mq($from: wide) {

.off-canvas-menu { 
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	background: $white;
	z-index: 4;
	transform: translateY(-110%);
	will-change: transform;
	transition: transform $globalTween;


	padding-top: $mobileNavHeight;
	@include mq($from: tablet) { padding-top: $tabletNavHeight; }
	@include mq($from: desktop) { padding-top: $desktopNavHeight; }


	@include mq($from: desktop) {
		display: none;
	}
	@include gel-wrap;
	&--inner-wrap {		
		@include gel-layout;
	}
	&--menu-wrapper {
		@include gel-layout-item;
		padding-top: ($ss*1);
	}
	.menu {
		a {
			color: $darkGrey;
			text-decoration: none;
			font-size: 1.4rem;
		}
		li {
			display: flex;
			align-items: center;
			&:before {
				content: url(../../svg/inline-arrow.svg);
				width: 30px;
				height: 20px;
				display: inline-block;
				margin-right: ($ss/2);
				// display: flex;
				align-items: center;
				// height: 200px;
			}
			margin-bottom: ($ss*1);
		}
	}

	.off-canvas--login-cta {

		// display: flex;
		// align-items: center;
		// justify-content: center;

		&:before {
			content: "";
			display: inline-block;
			width: 100%;
			height: 2px;
			background-color: $medGrey;
			// margin-left: ($ss/2);
			margin-bottom: ($ss/2);
			// @include mq($from: tablet) {
			// 	margin-left: ($ss/1);
			// }
		}

		span {
			// display: none;
			// @include mq($from: tablet) {
				display: inline-block;
				text-decoration: none;
				color: $darkGrey;
				margin: 0 ($ss/2);
				font-size: 1.4rem;
			// }
			transition: 0.2s;
			&:hover {
			  color: $red;
			}
		}
		svg {
			width: 30px;
		}

	}

}