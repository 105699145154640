// @include gel-wrap;
// @include gel-layout;
// @include gel-layout-item;
// @include gel-columns(6/12);
// @include mq($from: tablet) {
// @include mq($from: desktop) {
// @include mq($from: wide) {

$authorImgSize_mob 	: 150px;
$authorImgSize_tab 	: 300px;
$authorImgSize_desk : 300px;

.testimonials {

	overflow: hidden;

	.section-block--inner-wrap {
		@include gel-wrap;
	}

	.testimonials--title {
		text-align: center;
		margin-bottom: ($ss*1);
		@include mq($from: desktop) {
			text-align: left;
		}
	}

	.testimonial-group {
		@include mq($from: desktop) {
			@include gel-layout;
			// display: flex;
			.testimonials-tabs-wrapper {
				// flex: 0 0 20%;
				@include gel-layout-item;
				@include gel-columns(3/12);
			}
			.testimonials-wrapper {
				@include gel-layout-item;
				@include gel-columns(9/12);
				// flex: 0 0 80%;
				// max-width: 900px;
			}
		}
	}

	.testimonials-tabs-wrapper {
		@include mq($from: tablet) {
			margin-bottom: ($ss*1);
		}
		@include mq($from: desktop) {
			margin-bottom: 0;
		}
	}

	.testimonials-tabs {
		display: none;
		@include mq($from: tablet) {
			display: flex;
			justify-content: space-between;
			margin: 0 ($ss/2);
			// @include type-small-title;

			li {
				margin-bottom: 0;
				opacity: 0.6;
				display: inline-block;
				button {
					background: transparent;
					color: $white;
					border: none;
					padding: ($ss/1) 0 ($ss/4) 0;
					border-bottom: 3px solid transparent;
					@include type-slider-tabs;
					@include mq($from: desktop) {
						text-align: left;
					}
				}
				&.is-active {
					opacity: 1;
					button {
						border-bottom: 3px solid $white;
					}
				}
			}
		}
		@include mq($from: desktop) {
			flex-direction: column;
			justify-content: flex-start;
		}
	}

	.testimonials-wrapper {
		display: block;

	}

	.testimonials-slider {
	
	// 	display: flex;
	// 	flex-direction: column;
	// 	align-items: center;

		// max-width: 900px;

		padding-bottom: 50px;
		width: 100%;

		.slick-dots {
			bottom: 0px;
			@include mq($from: tablet) {
				display: none !important;
			}
		}

	}

	.testimonial-single-slide {
		// width: 90vw;
		// width: 100%;
		// display: flex;
		// width: 100%;
		// height: 400px;
		overflow: hidden;
		text-align: center;

		width: calc(100vw - #{($gel-spacing-unit*2)});
		@include mq($from: tablet) {
			// width: calc(100vw - #{($gel-spacing-unit*5)});
			width: 100%;
		}
		@include mq($from: desktop) {
			overflow: visible;
			// width: calc(100vw - #{($gel-spacing-unit*2)});
			.button {
				// margin-left: calc(100% - #{($authorImgSize_desk*1.1)});
				margin-right: #{($authorImgSize_desk*1.1)};
			}
		}
	}

	.slick-list {
		@include mq($from: desktop) {
			overflow: visible;
		}
	}

	.testimonial--testimonial {
		
		// display: flex;
		width: 100%;
		position: relative;

		&--content {
			// flex: 0 0 auto;
			// max-width: 70vw;
			width: 100%;
			// min-height: 200px;
			padding-top: ($authorImgSize_mob*0.4);
			width: calc(100% - #{($authorImgSize_mob*0.9)});
			@include mq($from: tablet) {
				padding-top: ($authorImgSize_tab*0.4);
				width: calc(100% - #{($authorImgSize_tab*0.9)});
			}
			@include mq($from: desktop) {
				padding-top: 0;
				width: calc(100% - #{($authorImgSize_desk*1.1)});
			}
			text-align: left;
			padding-bottom: ($ss/2);
			// margin: 0 0 ($ss/2) 0;

			

		}


		.testimonial--copy {
		// 	width: 100%;
			// @include type-medium-copy;
			// @include type-small-copy;
		}

		&--image {
			// flex: 0 0 30vw;
			// width: 30vw;
			// height: 30vw;
			// max-height: 200px;
			// max-width: 200px;
			position: absolute;
			right: 0;
			top: 0;
			@include mq($from: desktop) {
				top: -$ss;
			}
		}

	}

	.mobile-only { // move to utilities
		// (.testimonial--area)
		text-align: center;
		@include mq($from: tablet) {
			display: none;
		}
	}

	.testimonial--quote-marks {
		margin: ($ss/2) 0;
		width: 60px;
		@include mq($from: tablet) {
			width: 100px;
		}
	}



	.testimonials--author-image-wrapper {
		position: relative;
		height: $authorImgSize_mob;
		width: $authorImgSize_mob;
		@include mq($from: tablet) {
			height: $authorImgSize_tab;
			width: $authorImgSize_tab;
		}
		@include mq($from: desktop) {
			height: $authorImgSize_desk;
			width: $authorImgSize_desk;
		}
		// @include aspect-ratio(1,1);

		.testimonials--author-image .featured-image {
			// @include aspect-ratio(1,1);
			border-radius: 50%;
			
		}

		.testimonials--author-image {
			@include center();
			// width: 60%;
			width: ($authorImgSize_mob*0.6);
			height: ($authorImgSize_mob*0.6);
			@include mq($from: tablet) {
				height: ($authorImgSize_tab*0.6);
				width: ($authorImgSize_tab*0.6);
			}
			@include mq($from: desktop) {
				height: ($authorImgSize_desk*0.6);
				width: ($authorImgSize_desk*0.6);
			}
		}

		svg {

		}

		svg {
			// @include center();
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	ul, li {
	    margin-left: 0;
	    margin-bottom: 0;
	}

}


