.section-block.video_block {
	// iframe {
	// 	width: 100%;
	// }

	.iframe-wrapper {
		
		height: auto;
		@include aspect-ratio(16,9);	
		position: relative;
		
		// @include mq($from: desktop) {
		// 	height: auto;
		// 	@include aspect-ratio(16,9);			
		// }

		iframe {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}

		&__background {

			iframe.ytplayer {
				pointer-events: none;
			}

			// height: 100vw;

			// iframe {
			// 	// display: none;
			// 	// @include mq($from: desktop) {
			// 	// display: block;
			// 	pointer-events: none;
			// 	// position: absolute;
			// 	// @include center;
			// 	// min-height: 100vh;
			// 	// min-width: 100vw;
			// 	// width: calc(100vh * 16 / 9);
			// 	// height: calc(100vw * 9 / 16);
			// 	// min-height: 100vw;
			// 	// min-width: 100vw;
			// 	width: calc(200% * 16 / 9);
			// 	height: calc(100% * 9 / 16);
			// 	min-height: 100%;
			// 	min-width: 100%;
			// 	// width: 100vw;
			// 	// height: 100vw;
			// 	@include mq($from: desktop) {
			// 		min-height: 100%;
			// 		min-width: 100%;
			// 		width: calc(100% * 16 / 9);
			// 		height: calc(100% * 9 / 16);
			// 	}

			// 	// }

			// }

		}
	}

	.video_thumbnail {
		// @include aspect-ratio(1,1);
		height: 100vw;
		@include mq($from: desktop) {
			height: auto;
			@include aspect-ratio(16,9);			
		}
		position: relative;
		overflow: hidden;
		// iframe,
		.featured-image {
			// @include full-bg;
			// width: 100%;
			// height: 100%;
			// position: absolute;
			// top: 0;
			// left: 0;
		}
		iframe {
			// display: none;
			// @include mq($from: desktop) {
			// display: block;
			pointer-events: none;
			position: absolute;
			@include center;
			// min-height: 100vh;
			// min-width: 100vw;
			// width: calc(100vh * 16 / 9);
			// height: calc(100vw * 9 / 16);
			// min-height: 100vw;
			// min-width: 100vw;
			width: calc(200% * 16 / 9);
			height: calc(100% * 9 / 16);
			min-height: 100%;
			min-width: 100%;
			// width: 100vw;
			// height: 100vw;
			@include mq($from: desktop) {
				min-height: 100%;
				min-width: 100%;
				width: calc(100% * 16 / 9);
				height: calc(100% * 9 / 16);
			}

			// }

		}
		svg {
			// position: absolute;
			@include center;
			width: 140px; // temp
			@include mq($from: tablet) {
				width: 200px; // temp
			}
		}
		.play-cta {
			@include center;
			width: 300px; // temp
			text-align: center;
			color: $yellow;
			left: 54%; // offset for last text letter spacing
			@include mq($from: tablet) {
				left: 50.5%; // offset for last text letter spacing
			}
		}
	}

}