// .no-focus-outline a:focus,
// .no-focus-outline button:focus {
//   outline: none;
// }

body:not(.user-is-tabbing) div:focus,
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}

