// rem units
$base-font-size: 16px;

@mixin rem($property, $px_values) {
    // Convert the baseline into rems
    $baseline_rem: $base / 1rem; // Print the first line in pixel values
    #{$property}: $px_values; // If only one (numeric) value, return the property/value line for it.
    @if type-of($px_values)=="number" {
        #{$property}: $px_values / $baseline_rem;
    }
    @else {
        // Create an empty list that we can dump values into
        $rem_values: ();

        @each $value in $px_values {
            // If the value is not numeric then just return it //
            @if type-of($value) !="number" {
                $rem_values: append($rem_values, $value);
            }
            @else if $value==0 {
                $rem_values: append($rem_values, $value);
            }
            @else {
                $rem_values: append($rem_values, $value / $baseline_rem);
            }
        } // Return the property and its list of converted values
        #{$property}: $rem_values;
    }
}


// rem units
@function rem($px, $base: $base-font-size) {
    @return $px / $base * 1rem;
}

// My Mixins
// =========
@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    >.content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@mixin nice-transition {
    -webkit-transition: all 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -moz-transition: all 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -ms-transition: all 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    -o-transition: all 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transition: all 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@mixin full-bg {
    background-position: center center;
    background-repeat: no-repeat;
    border-bottom: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@mixin center($axis: "both") {
    position: absolute;
    @if $axis=="y" {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    @if $axis=="x" {
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
    }
    @if $axis=="both" {
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}



/// linear gradient
@mixin vertical-linear-Gradient($top, $bottom) {
    background: $top;
    /* Old browsers */
    background: -moz-linear-gradient(top, $top 0%, $bottom 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $top), color-stop(100%, $bottom));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, $top 0%, $bottom 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, $top 0%, $bottom 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, $top 0%, $bottom 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, $top 0%, $bottom 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0);
    /* IE6-9 */
}

// usage
// .button{
//     @include vertical-linear-Gradient(#cccccc, #666666);
// }
/// angled gradient....
/// Convert angle
/// @author Chris Eppstein
/// @param {Number} $value - Value to convert
/// @param {String} $unit - Unit to convert to
/// @return {Number} Converted angle
@function convert-angle($value, $unit) {
    $convertable-units: deg grad turn rad;
    $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
    @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
        @return $value / nth($conversion-factors, index($convertable-units, unit($value))) * nth($conversion-factors, index($convertable-units, $unit));
    }

    @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

/// Test if `$value` is an angle
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
    $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
    $is-angle: type-of($value)=='number' and index('deg' 'grad' 'turn' 'rad', unit($value));

    @return $is-direction or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
@function legacy-direction($value) {
    @if is-direction($value)==false {
        @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
    }

    $conversion-map: ( to top: bottom,
    to top right: bottom left,
    to right top: left bottom,
    to right: left,
    to bottom right: top left,
    to right bottom: left top,
    to bottom: top,
    to bottom left: top right,
    to left bottom: right top,
    to left: right,
    to left top: right bottom,
    to top left: bottom right);

    @if map-has-key($conversion-map, $value) {
        @return map-get($conversion-map, $value);
    }

    @return 90deg - convert-angle($value, 'deg');
}

/// Mixin printing a linear-gradient 
/// as well as a plain color fallback 
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {String | List | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
    @if is-direction($direction)==false {
        $color-stops: ($direction, $color-stops);
        $direction: 180deg;
    }

    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
}



// Only display content to screen readers
//
// See: https://a11yproject.com/posts/how-to-hide-content/
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/

@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
//
// Useful for "Skip to main content" links; see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
//
// Credit: HTML5 Boilerplate

@mixin sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
}



/////////////// MORE MIXINS ////////////////////

@mixin text-shadow ($string: 0 1px 3px rgba(0, 0, 0, 0.25)) {
    text-shadow: $string;
}
@mixin box-shadow ($string) {
    -webkit-box-shadow: $string;
    -moz-box-shadow:    $string;
    box-shadow:         $string;
}

@mixin box-sizing ($type: border-box) {
    -webkit-box-sizing: $type;
    -moz-box-sizing:    $type;
    box-sizing:         $type;
}

@mixin border-radius ($radius: 5px) {
    -webkit-border-radius: $radius;
    -moz-border-radius:    $radius;
    -ms-border-radius:     $radius;
    -o-border-radius:      $radius;
    border-radius:         $radius;

    -moz-background-clip:    padding;
    -webkit-background-clip: padding-box;
    background-clip:         padding-box;
}
@mixin border-radiuses ($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
    -webkit-border-top-right-radius:    $topright;
    -webkit-border-bottom-right-radius: $bottomright;
    -webkit-border-bottom-left-radius:  $bottomleft;
    -webkit-border-top-left-radius:     $topleft;

    -moz-border-radius-topright:        $topright;
    -moz-border-radius-bottomright:     $bottomright;
    -moz-border-radius-bottomleft:      $bottomleft;
    -moz-border-radius-topleft:         $topleft;

    border-top-right-radius:            $topright;
    border-bottom-right-radius:         $bottomright;
    border-bottom-left-radius:          $bottomleft;
    border-top-left-radius:             $topleft;

    -moz-background-clip:    padding; 
    -webkit-background-clip: padding-box; 
    background-clip:         padding-box; 
}

@mixin opacity ($opacity: 0.5) {
    -webkit-opacity:    $opacity;
    -moz-opacity:       $opacity;
    opacity:        $opacity;
}

@mixin gradient ($startColor: #eee, $endColor: white) {
    background-color: $startColor;
    background: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
    background: -webkit-linear-gradient(top, $startColor, $endColor);
    background: -moz-linear-gradient(top, $startColor, $endColor);
    background: -ms-linear-gradient(top, $startColor, $endColor);
    background: -o-linear-gradient(top, $startColor, $endColor);
}
@mixin horizontal-gradient ($startColor: #eee, $endColor: white) {
    background-color: $startColor;
    background-image: -webkit-gradient(linear, left top, right top, from($startColor), to($endColor));
    background-image: -webkit-linear-gradient(left, $startColor, $endColor);
    background-image: -moz-linear-gradient(left, $startColor, $endColor);
    background-image: -ms-linear-gradient(left, $startColor, $endColor);
    background-image: -o-linear-gradient(left, $startColor, $endColor);
}

@mixin animation ($name, $duration: 300ms, $delay: 0, $ease: ease) {
    -webkit-animation: $name $duration $delay $ease;
    -moz-animation:    $name $duration $delay $ease;
    -ms-animation:     $name $duration $delay $ease;
}

@mixin transition ($transition) {
    -webkit-transition: $transition;  
    -moz-transition:    $transition;
    -ms-transition:     $transition; 
    -o-transition:      $transition;  
}
@mixin transform($string){
    -webkit-transform:  $string;
    -moz-transform:      $string;
    -ms-transform:       $string;
    -o-transform:        $string;
}
@mixin scale ($factor) {
    -webkit-transform: scale($factor);
    -moz-transform:      scale($factor);
    -ms-transform:       scale($factor);
    -o-transform:        scale($factor);
}
@mixin rotate ($deg) {
    -webkit-transform: rotate($deg);
    -moz-transform:      rotate($deg);
    -ms-transform:       rotate($deg);
    -o-transform:        rotate($deg);
}
@mixin skew ($deg, $deg2) {
    -webkit-transform:       skew($deg, $deg2);
    -moz-transform:      skew($deg, $deg2);
    -ms-transform:       skew($deg, $deg2);
    -o-transform:        skew($deg, $deg2);
}
@mixin translate ($x, $y:0) {
    -webkit-transform:       translate($x, $y);
    -moz-transform:      translate($x, $y);
    -ms-transform:       translate($x, $y);
    -o-transform:        translate($x, $y);
}
@mixin translate3d ($x, $y: 0, $z: 0) {
    -webkit-transform:       translate3d($x, $y, $z);
    -moz-transform:      translate3d($x, $y, $z);
    -ms-transform:       translate3d($x, $y, $z);
    -o-transform:        translate3d($x, $y, $z);
}
@mixin perspective ($value: 1000) {
    -webkit-perspective:    $value;
    -moz-perspective:   $value;
    -ms-perspective:    $value;
    perspective:        $value;
}
@mixin transform-origin ($x:center, $y:center) {
    -webkit-transform-origin: $x $y;
    -moz-transform-origin:    $x $y;
    -ms-transform-origin:     $x $y;
    -o-transform-origin:      $x $y;
}

@mixin reset-box-sizing ($size:content-box) {
  &,
  *,
  *:before,
  *:after {
    @include box-sizing($size);
  }
}

@mixin truncate ($max-width: 250px) {
  max-width: $max-width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin background-size ($string: contain) {
    -webkit-background-size: $string;
    -moz-background-size: $string;
    -o-background-size: $string;
    background-size: $string;
}

@mixin placeholder ($color: #999) {
    &::-webkit-input-placeholder { /* WebKit browsers */
        color: $color;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: $color;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: $color;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10+ */
        color: $color;
    }
}



  


