.wysiwyg {

	padding-top: 140px; // temp

	.section-block--content {
		> * {
			margin-bottom: ($ss*1);
		}
		p {
			@include type-promo-paragraph; // doing this takes it away from being a WYSIWYG, so section should be renamed
		}
		// display: flex;
		// flex-direction: column;
		// align-items: center;
		margin: 0 auto;
		max-width: 700px;
	}
}