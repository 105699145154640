// @include gel-wrap;
// @include gel-layout;
// @include gel-layout-item;
// @include gel-columns(6/12);
// @include mq($from: tablet) {
// @include mq($from: desktop) {
// @include mq($from: wide) {


// defined in _settings
// $mobileNavHeight
// $tabletNavHeight
// $desktopNavHeight

body.menu-is-open {
    .off-canvas-menu {
        transform: none;
    }
    .hamburger--label {
        &__closed {
            display: none;
        }

        &__open {
            display: block;
        }
    }
}

.site-header-background {
	box-shadow: rgba(100, 100, 111, 0.4) 0px 4px 29px 0px;
	position: fixed;
	z-index: 1;
	width: 100%;
	background: $red;
	height: $mobileNavHeight;
	@include mq($from: tablet) { height: $tabletNavHeight; }
	@include mq($from: desktop) { height: $desktopNavHeight; }

}

.site-header {

	position: fixed;
	width: 100%;
	background: $white;
	z-index: 5;

	// height: $mobileNavHeight;
	// @include mq($from: tablet) { height: $tabletNavHeight; }
	// @include mq($from: desktop) { height: $desktopNavHeight; }

	// @include gel-wrap;

	// width: 100%;

	// background-color: teal;

	&--outer-wrapper {
		@include gel-wrap;
		height: 100%;
		
		height: $mobileNavHeight;
		@include mq($from: tablet) { height: $tabletNavHeight; }
		@include mq($from: desktop) { height: $desktopNavHeight; }

		position: relative;
		z-index: 8;
	}

	&--inner-wrapper {

		// @include gel-layout;
		// width: 100%;


		position: relative;
		z-index: 10;
		
		height: 100%;

		display: flex;
		justify-content: space-between;
		align-items: center;

	}

	&--left,
	&--right {
		// height: 100%;
		// display: flex;
		// align-items: center;
		a {
			text-decoration: none;
		}
	}


	&--left {

		&--logo {

			// padding: $ss 0;

			a { display: block; }

			svg {
				// width: 100%;
				// height: 100%;
				width: 60px;
				@include mq($from: tablet) { width: 100px; }
				@include mq($from: desktop) { width: 140px; }
			}
			
		}

	}

	&--right {

		// use if no logo on mobile menu 
		// position: relative;
		// z-index: 10;

		display: flex;

		.hamburger {
			display: flex;
			height: 100%;
			align-items: center;

			// margin-right: ($ss/2);
			
		}

		&--mobile-toggle {

			@include mq($from: desktop) { display: none; }

		    .hamburger--label {
		    	margin-right: ($ss/2);
		        &__closed { 	display: block; }
		        &__open { 		display: none; }
		    }

		}

		&--login-cta {

			@include mq($until: desktop) { display: none; }

			display: flex;
			align-items: center;
			justify-content: center;

			&:before {
				content: "";
				display: inline-block;
				width: 2px;
				height: 100%;
				background-color: $medGrey;
				margin-left: ($ss/2);
				margin-right: ($ss/2);
				@include mq($from: tablet) {
					margin-left: ($ss/1);
				}
			}

			span {
				display: none;
				@include mq($from: tablet) {
					display: inline-block;
					text-decoration: none;
					color: $darkGrey;
					margin: 0 ($ss/2);
				}
				transition: 0.2s;
				&:hover {
				  color: $red;
				}
			}
			svg {
				width: 30px;
			}


		}

		&--desktop-menu {

			@include mq($until: desktop) { display: none; }

			@include mq($from: desktop) {
				display: flex;
				align-items: center;
			}

			ul {
				display: flex;
				flex-direction: row;

				li {
					margin-right: ($ss/2);
					@include mq($from: desktop) { margin-right: ($ss/1); }
					&:last-of-type {
						margin-right: 0;						
					}
					a {
						text-decoration: none;
						color: $darkGrey;
						&:hover {
						  color: $red;
						}
					}
				}
			}

		}
		
	}


	// &--mobile-header {

	// 	z-index: 10;
	// 	position: relative;
	// 	height: $mobileNavHeight;

	// 	@include gel-layout;
	// 	// box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.6);

	// 	@include mq($from: desktop) {
	// 		display: none;
	// 	}

	// 	&--left {
	// 		@include gel-layout-item;
	// 		@include gel-columns(6/12);

	// 		display: flex;
	// 		flex-direction: row;
	// 		justify-content: flex-start;
	// 		align-items: center;

	// 	}

	// 	&--right {
	// 		@include gel-layout-item;
	// 		@include gel-columns(6/12);

	// 		display: flex;
	// 		flex-direction: row;
	// 		justify-content: flex-end;
	// 		align-items: center;

	// 	}

	//     svg {
			
	// 		display: block;
	//         // margin: 0 ($ss*1);
	//         margin: 0;
	//         width: 30px;

	//         @media only screen and (min-width: 768px) {
	//             margin: 0 ($ss*1);
	//             max-width: 45px;
	//         }
	//     }

	//     .hamburger--label {
	//         &__closed { 	display: block; }
	//         &__open { 		display: none; }
	//     }

	// }

	// &--desktop-header {

	// 	display: none;


	// 	@include mq($from: desktop) {

	// 		@include gel-layout;
	// 		display: flex;

	// 	}

	// 	&--left {
	// 		@include gel-layout-item;
	// 		@include gel-columns(3/12);
	// 	}

	// 	&--right {
	// 		@include gel-layout-item;
	// 		@include gel-columns(9/12);

	// 		ul {
	// 			height: 100%;
	// 			display: flex;
	// 			flex-direction: row;
	// 			justify-content: flex-end;
	// 			align-items: center;

	// 			li {
	// 				margin: 0 0 0 ($ss/2);
	// 			}
	// 		}

	// 	}

	//     svg {
			
	// 		display: block;
	//         margin: 0 ($ss*1);
	//         max-width: 30px;

	//         @media only screen and (min-width: 768px) {
	//             margin: 0 ($ss*1);
	//             max-width: 45px;
	//         }
	//     }

	// }

    
}