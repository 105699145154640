  /*
  Variables.
  ==================================================
  */

  :root {
    --flag-invalid-color: #f00;
    --spacing-double: calc(2 * var(--spacing));
  }

body.trashy {

  /*
  Warnings for `head` and `style`.
  =================================================
  */

  head {
    display: block;

    max-width: var(--max-page-width);

    margin-left: auto;
    margin-right: auto;

    padding-left: var(--spacing);
    padding-right: var(--spacing);
  }

  body link,
  body style,
  head > :first-child:not([charset="utf-8"]),
  head > style:not(:first-of-type),
  link[href^="http:"],
  script:not([src]),
  script[src]:not([async]),
  script[src^="http:"],
  title:empty {
    display: block;

    color: transparent;
    font-size: 0;
    line-height: 0;
    text-indent: -99999px;

    position: relative;

    width: 100%;
    height: var(--spacing);
    margin-bottom: var(--spacing);
  }

  /* Hide injected `script`. */
  html > script {
    display: none !important;
  }

  /*
  Outline "invalid" markup.
  ==================================================
  */

  :not(dl) > div > dd,
  :not(dl) > div > dt,
  :not(dl):not(div) > dd,
  :not(dl):not(div) > dt,
  :not(fieldset) > legend,
  :not(figure) > figcaption,
  :not(ul):not(ol) > li,
  [style*="display: none"]:not([hidden]),
  [style*="display:none"]:not([hidden]),
  [style]:not(body):not(html):not(textarea),
  a[href^="http:"],
  a[target]:not([rel*="noopener"]),
  a[target]:not([rel*="noreferrer"]),
  abbr div,
  abbr:not([title]),
  b div,
  body :empty:not([aria-hidden]):not(area):not(br):not(col):not(embed):not(hr):not(iframe):not(img):not(input):not(link):not(object):not(output):not(param):not(script):not(source):not(style):not(textarea):not(track):not(wbr),
  button:not([type]),
  cite div,
  code div,
  dl > :not(dt):not(dd):not(div),
  dl > dd:first-child,
  dl > div > :not(dt):not(dd),
  dl > div > dd:first-child,
  em div,
  fieldset > :not(legend):first-child,
  fieldset > legend:not(:first-child),
  figure > figcaption:not(:first-child):not(:last-child) ~ :last-child:not(figcaption),
  figure > figcaption:not(:first-child):not(:last-child),
  figure > figcaption:not(:first-of-type),
  i div,
  img:not([alt]),
  img:not([src]),
  input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([id]):not([aria-label]):not([aria-labelledby]):not([title]),
  input:not([type]),
  input:not([type]),
  input[type="checkbox"] + label,
  input[type="radio"] + label,
  input[type="radio"]:not([name]),
  label div,
  label:not([for]),
  ol > :not(li),
  p div,
  q div,
  select:not([id]):not([aria-label]):not([aria-labelledby]),
  small div,
  span div,
  strong div,
  table table tr:first-child td:first-child,
  table[role="presentation"] tr:first-child td:first-child,
  tbody th[scope]:not([scope="row"]),
  textarea:not([id]):not([aria-label]):not([aria-labelledby]),
  textarea[style]:not([style*="height:"]),
  thead th[scope]:not([scope="col"]),
  ul > :not(li) {
    box-shadow: 0 0 0 2px var(--flag-invalid-color);
    position: relative;
  }

  [style*="display:none"]:not([hidden]),
  [style*="display: none"]:not([hidden]) {
    display: block !important;
  }

  /*
  Visual style for warning indicators.
  ==================================================
  */

  :not(dl) > div > dd::after,
  :not(dl) > div > dt::after,
  :not(dl):not(div) > dd::after,
  :not(dl):not(div) > dt::after,
  :not(fieldset) > legend::after,
  :not(figure) > figcaption::after,
  :not(ul):not(ol) > li::after,
  [style]:not(body):not(html):not(textarea)::after,
  a[href^="http:"]::after,
  a[target]:not([rel*="noopener"])::after,
  a[target]:not([rel*="noreferrer"])::after,
  abbr div::after,
  abbr:not([title])::after,
  b div::after,
  body :empty:not([aria-hidden]):not(area):not(br):not(col):not(embed):not(hr):not(iframe):not(img):not(input):not(link):not(object):not(output):not(param):not(script):not(source):not(style):not(textarea):not(track):not(wbr)::after,
  body link::after,
  body style::after,
  body[style]::before,
  button:not([type])::after,
  cite div::after,
  code div::after,
  div > div:not([role]):not(:empty):only-child::after,
  dl > :not(dt):not(dd):not(div)::after,
  dl > dd:first-child::after,
  dl > div > :not(dt):not(dd)::after,
  dl > div > dd:first-child::after,
  em div::after,
  fieldset > :not(legend):first-child::after,
  fieldset > legend:not(:first-child)::after,
  figure > figcaption:not(:first-child):not(:last-child) ~ :last-child:not(figcaption)::after,
  figure > figcaption:not(:first-child):not(:last-child)::after,
  figure > figcaption:not(:first-of-type)::after,
  head > :first-child:not([charset="utf-8"])::after,
  head > style:not(:first-of-type)::after,
  html:not([lang]) head::before,
  i div::after,
  img:not([alt])::after,
  img:not([src])::after,
  input:not([type])::after,
  input[type="checkbox"] + label::after,
  input[type="radio"] + label::after,
  label div::after,
  label:not([for])::after,
  link[href^="http:"]::after,
  ol > :not(li)::after,
  p div::after,
  q div::after,
  script:not([src])::after,
  script[src]:not([async])::after,
  script[src^="http:"]::after,
  small div::after,
  span div::after,
  strong div::after,
  table table tr:first-child td:first-child::after,
  table[role="presentation"] tr:first-child td:first-child::after,
  tbody th[scope]:not([scope="row"])::after,
  thead th[scope]:not([scope="col"])::after,
  title:empty::after,
  ul > :not(li)::after {
    background: var(--flag-invalid-color);
    color: #fff;
    display: inline-block;

    font-family: Verdana, sans-serif;
    font-size: 1.1rem;
    font-weight: normal;
    line-height: 1.5;
    text-decoration: none;
    text-indent: 0;
    white-space: nowrap;

    padding-left: 0.5rem;
    padding-right: 0.5rem;

    pointer-events: none;

    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
  }

  body link::after,
  body link[href^="http:"]::after,
  body script:not([src])::after,
  body script[src]:not([async])::after,
  body script[src^="http:"]::after,
  body style::after {
    top: 0;
  }

  /*
  Inline `style`.
  ==================================================
  */

  [style]:not(body):not(html):not(textarea)::after {
    content:
      'Inline [style="…"] detected'
    ;
  }

  [style*="display:none"]:not([hidden])::after,
  [style*="display: none"]:not([hidden])::after {
    content:
      'Use [hidden] instead of [style="display: none"]'
    ;
  }

  body[style]::before {
    position: static;
    margin-bottom: var(--spacing);

    content:
      '<body> has inline [style="…"]'
    ;
  }

  /*
  Risky links.
  ==================================================
  */

  a[href^="http:"]::after {
    content:
      'Link to "http:" site. Does "https:" equivalent exist?'
    ;
  }

  a[target]:not([rel*="noopener"])::after,
  a[target]:not([rel*="noreferrer"])::after {
    content:
      '[target="…"] also needs [rel="noopener noreferrer"]'
    ;
  }

  /*
  Table: Incorrect `scope` values.
  ==================================================
  */

  thead th[scope]:not([scope="col"])::after {
    content:
      'Expected: [scope="col"]. Actual: [scope="' attr(scope) '"]'
    ;
  }

  tbody th[scope]:not([scope="row"])::after {
    content:
      'Expected: [scope="row"]. Actual: [scope="' attr(scope) '"]'
    ;
  }

  /*
  Invalid `figcaption` nesting.
  ==================================================
  */

  :not(figure) > figcaption::after {
    content:
      "<figcaption> must be a child of <figure>"
    ;
  }

  figure > figcaption:not(:first-child):not(:last-child)::after,
  figure > figcaption:not(:first-child):not(:last-child) ~ :last-child:not(figcaption)::after {
    content:
      "<figcaption> must be first/last child of <figure>"
    ;
  }

  figure > figcaption:not(:first-of-type)::after {
    content:
      "<figure> should only contain one <figcaption>" !important
    ;
  }

  /*
  Invalid `li` nesting.
  ==================================================
  */

  :not(ul):not(ol) > li::after {
    content:
      "<li> is missing a parent <ul> or <ol>"
    ;
  }

  ol > :not(li)::after {
    content:
      "Immediate child of <ol> must be <li>"
    ;
  }

  ul > :not(li)::after {
    content:
      "Immediate child of <ul> must be <li>"
    ;
  }

  /*
  Invalid `dd` nesting.
  ==================================================
  */

  :not(dl):not(div) > dd::after {
    content:
      "<dd> is missing a parent <dl> or <div>"
    ;
  }

  :not(dl):not(div) > dt::after {
    content:
      "<dt> is missing a parent <dl> or <div>"
    ;
  }

  :not(dl) > div > dd::after {
    content:
      "<dd> with parent <div> is missing <dl> grandparent"
    ;
  }

  :not(dl) > div > dt::after {
    content:
      "<dt> with parent <div> is missing <dl> grandparent"
    ;
  }

  dl > :not(dt):not(dd):not(div)::after {
    content:
      "Immediate child of <dl> must be <dt>, <dd>, or <div>"
    ;
  }

  dl > dd:first-child::after {
    content:
      "<dt> or <div> should be :first-child of <dl>, not <dd>"
    ;
  }

  dl > div > dd:first-child::after {
    content:
      "<dt> should be :first-child of <div> within <dl>, not <dd>"
    ;
  }

  dl > div > :not(dt):not(dd)::after {
    content:
      "Immediate child of <div> within <dl> must be <dt> or <dd>."
    ;
  }

  /*
  Invalid `legend` position in `fieldset`.
  ==================================================
  */

  fieldset > :not(legend):first-child::after {
    content:
      "Invalid :first-child of <legend>"
    ;
  }

  :not(fieldset) > legend::after,
  fieldset > legend:not(:first-child)::after {
    content:
      "<legend> must be :first-child of <fieldset>"
    ;
  }

  /*
  Missing `for` on `label`.
  ==================================================
  */

  label:not([for])::after {
    content:
      '<label> needs [for="…"] to match <input> [id="…"]'
    ;
  }

  /*
  Missing `type` on `button`.
  ==================================================
  */

  button:not([type])::after {
    content:
      '<button> needs [type="…"]'
    ;
  }

  /*
  Missing `src` on `image`.
  ==================================================
  */

  img:not([src])::after {
    content:
      '<img> needs [src="…"]'
    ;
  }

  /*
  Adjacent "checkbox" and "radio" labels.
  ==================================================
  */

  input[type="checkbox"] + label::after {
    content:
      '<input type="checkbox"> should be nested inside <label>'
    ;
  }

  input[type="radio"] + label::after {
    content:
      '<input type="radio"> should be nested inside <label>'
    ;
  }

  /*
  Missing `title` in `abbr`.
  ==================================================
  */

  abbr:not([title])::after {
    content:
      '<abbr> needs [title="…"]'
    ;
  }

  /*
  Empty elements.
  ==================================================
  */

  body :empty:not([aria-hidden]):not(area):not(br):not(col):not(embed):not(hr):not(iframe):not(img):not(input):not(link):not(object):not(output):not(param):not(script):not(source):not(style):not(textarea):not(track):not(wbr)::after {
    content:
      'Empty element: Consider [aria-hidden="true"]'
    ;
  }

  /*
  Flag missing `<html lang="…">` attribute.
  =================================================
  */

  html:not([lang]) head::before {
    position: static;
    margin-top: var(--spacing);

    content:
      '<html> needs [lang="…"]'
    ;
  }

  /*
  Flag `<table role="presentation">`.
  =================================================
  */

  table[role="presentation"] tr:first-child td:first-child::after {
    content:
      '<table> has [role="presentation"] - Use Flexbox instead?'
    ;
  }

  /*
  Flag nested `table`.
  =================================================
  */

  table table tr:first-child td:first-child::after {
    content:
      "Invalid nested <table> - Use CSS Grid instead?"
    ;
  }

  /*
  Missing `meta[charset]` tag.
  =================================================
  */

  head > :first-child:not([charset="utf-8"])::after {
    content:
      '<head> needs <meta charset="utf-8"> as :first-child'
    ;
  }

  /*
  Empty `title`.
  =================================================
  */

  title:empty::after {
    content:
      "<title> should not be empty"
    ;
  }

  /*
  Warnings for `script` tags.
  =================================================
  */

  script:not([src])::after {
    content:
      'Move inline <script> to external *.js file'
    ;
  }

  script[src]:not([async])::after {
    content:
      'Consider [async] for <script> with [src="…"]'
    ;
  }

  script[src]:not([src=""]):not([async])::after {
    content:
      'Consider [async] for <script> with [src="' attr(src) '"]'
    ;
  }

  script[src^="http:"]::after {
    content:
      'Consider "https:" for <script> with [src="' attr(src) '"]'
    ;
  }

  /*
  Warnings for `style` tags.
  =================================================
  */

  head > style:not(:first-of-type)::after {
    content:
      "Only use one <style> for crucial CSS"
    ;
  }

  body style::after {
    content:
      "<body> should not contain <style>"
    ;
  }

  /*
  Warnings for `link` tags.
  =================================================
  */

  link[href^="http:"]::after {
    content:
      'Consider "https:" for <link> with [href="' attr(href) '"]'
    ;
  }

  body link::after {
    content:
      "<body> should not contain <link>"
    ;
  }

  body link[href]:not([href=""])::after {
    content:
      '<body> should not contain <link href="' attr(href) '">'
    ;
  }

  /*
  Improperly nested `div`.
  =================================================
  */

  abbr div::after {
    content:
      "<div> is not allowed within <abbr>"
    ;
  }

  b div::after {
    content:
      "<div> is not allowed within <b>"
    ;
  }

  cite div::after {
    content:
      "<div> is not allowed within <cite>"
    ;
  }

  code div::after {
    content:
      "<div> is not allowed within <code>"
    ;
  }

  i div::after {
    content:
      "<div> is not allowed within <i>"
    ;
  }

  em div::after {
    content:
      "<div> is not allowed within <em>"
    ;
  }

  label div::after {
    content:
      "<div> is not allowed within <label>"
    ;
  }

  q div::after {
    content:
      "<div> is not allowed within <q>"
    ;
  }

  small div::after {
    content:
      "<div> is not allowed within <small>"
    ;
  }

  span div::after {
    content:
      "<div> is not allowed within <span>"
    ;
  }

  strong div::after {
    content:
      "<div> is not allowed within <strong>"
    ;
  }

  /*
  Improperly nested `div` inside `p`.
  =================================================
  */

  /*
    NOTE: Chrome and Firefox render this oddly.

    ```
    <!-- ACTUAL. -->
    <p class="foo">
      <div class="bar">Invalid</div>
    </p>
    ```

    ```
    <!-- RENDERED. -->
    <p class="foo">
    </p>
    <div class="bar">
      Invalid
    </div>
    <p></p>
    ```
  */

  p + div:not(:empty) + p:empty {
    box-shadow: none !important;
    top: calc(-1 * var(--spacing));
  }

  p div::after,
  p + div:not(:empty) + p:empty::after {
    content:
      "<div> is not allowed within <p>" !important
    ;
  }

  /*
  "Div-itis" detection.
  ==================================================
  */

  div > div:not([role]):not(:empty):only-child::after {
    --offset: calc(1px + var(--spacing));

    margin-left: calc(-1 * var(--offset));
    margin-top: var(--offset);

    content:
      '"Div-itis!" - Needlessly nested <div> tags (2).'
    ;
  }

  div > div:not([role]):only-child > div:not([role]):not(:empty):only-child::after {
    z-index: 2;

    content:
      '"Div-itis!" - Needlessly nested <div> tags (3).'
    ;
  }

  div > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):not(:empty):only-child::after {
    z-index: 3;

    content:
      '"Div-itis!" - Needlessly nested <div> tags (4).'
    ;
  }

  div > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):not(:empty):only-child::after {
    z-index: 4;

    content:
      '"Div-itis!" - Needlessly nested <div> tags (5).'
    ;
  }

  div > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):not(:empty):only-child::after {
    z-index: 5;

    content:
      '"Div-itis!" - Needlessly nested <div> tags (6).'
    ;
  }

  div > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):not(:empty):only-child::after {
    z-index: 6;

    content:
      '"Div-itis!" - Needlessly nested <div> tags (7).'
    ;
  }

  div > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):not(:empty):only-child::after {
    z-index: 7;

    content:
      '"Div-itis!" - Needlessly nested <div> tags (8).'
    ;
  }

  div > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):only-child > div:not([role]):not(:empty):only-child::after {
    z-index: 8;

    content:
      '"Div-itis!" - Needlessly nested <div> tags (9+).'
    ;
  }

}