// https://www.degordian.com/education/blog/best-practice-for-responsive-typography/

// // // // // // // // // // // // // // // // // // // // // // // // // // // // 
// TO DO.......                                              //////////////
// FONT LOADING - GOOGLE FONTS:                              ////////////// 
//https://www.smashingmagazine.com/2019/06/optimizing-google-fonts-performance/
// // // // // // // // // // // // // // // // // // // // // // // // // // // // 



/**
 * ALT FONT SIZE CALC
 *
 * Convert font-size from px to rem with px fallback
 *
 * @param $size - the value in pixel you want to convert
 *
 * e.g. p {@include fontSize(12px);}
 * 
 */

// Function for converting a px based font-size to rem.
// @function calculateRem($size) {
//  @return math.div($size, 16px) * 1rem;
// }

// // Mixin that will include the fall back px declaration as well as the calculated rem value.
// @mixin fontSize($size) {
//   font-size: $size;
//   font-size: calculateRem($size);
// }





// unitless function to convert px to em
@function rem($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @if (unitless($context)) {
    $context: $context * 1px;
  }
  @return $pixels / $context * 1rem;
  // @return math.div($pixels, 16px) * 1rem;
}

// breakpoint mixin
@mixin breakpoint($mq01: 0, $mq2: false, $maxmin: max-width) {
    @if $mq2 == false {
        @media ($maxmin: $mq01) {
            @content;
        }
    }
    @else {
        @media (min-width: $mq01) and (max-width: $mq2) {
            @content;
        }
    }
}

// responsive font size mixin
@mixin font-size-map($font-size-map) {
    @each $breakpoint, $font-size in $font-size-map {
        @if $breakpoint == null {
            font-size: $font-size;
            font-size: rem($font-size);
        } @else {
            @include breakpoint($breakpoint) {
                font-size: $font-size;
                font-size: rem($font-size);
            }
        }
    }
}

// breakpoint variables
// (duplicated here from settings) -> $mq-breakpoints: (mobile: 320px, tablet: 740px, desktop: 980px, wide: 1300px) !default;
$wide:    1170px;
$desktop: 1024px;
$tablet:  780px;
$mobile:  480px;

//fonts
$titleFont: "gilroy-semibold",
sans-serif;
$bodyFont: "gilroy-regular",
sans-serif;

body {
    font-family: $bodyFont;
}

h1,h2,h3,h4,h5,h6 {
    font-family: $titleFont;
    line-height: 1.2;
    // font-weight: 400;
    margin: ($sitespacing/2) 0;
    // color: $red;
    // color: $darkgrey;
    // text-transform: uppercase;
}

a {
  color: $red;
  text-decoration: underline;
  transition: 0.2s;
  &:hover {
    // color: $darkgrey;
  }
}

p {
  margin-bottom: ($ss/2);
  line-height: 1.4;
}

@mixin bold {
    // font-family: $sansSerifFont;
    font-weight: 700;
}

.bold, b, strong { @include bold; }

@mixin uppercase { text-transform: uppercase; }

.bold            { @include bold; }
.uppercase       { @include uppercase; }


// body font size
$browser-context: 16;

// http://www.layoutgridcalculator.com/typographic-scale/
// typography scale

$body-font-size:            (null: 18px,   $desktop: 18px,     $tablet: 16px,      $mobile: 15px );

$alpha-font-size:           (null: 42px,   $desktop: 42px,     $tablet: 36px,      $mobile: 24px );
$beta-font-size:            (null: 42px,   $desktop: 42px,     $tablet: 36px,      $mobile: 24px );
$promo-copy-font-size:      (null: 19px,   $desktop: 19px,     $tablet: 19px,      $mobile: 18px );
$small-title-font-size:     (null: 21px,   $desktop: 21px,     $tablet: 21px,      $mobile: 21px );
$medium-font-size:          (null: 22px,   $desktop: 22px,     $tablet: 22px,      $mobile: 18px );
$list-font-size:            (null: 18px,   $desktop: 18px,     $tablet: 18px,      $mobile: 18px );
$small-font-size:           (null: 14px,   $desktop: 14px,     $tablet: 14px,      $mobile: 11px );
$testimonial-copy-font-size:(null: 36px,   $desktop: 36px,     $tablet: 24px,      $mobile: 18px );

$button-font-size:          (null: 16px,   $desktop: 16px,     $tablet: 18px,      $mobile: 24px );
$credits-font-size:         (null: 24px,   $desktop: 24px,     $tablet: 24px,      $mobile: 24px );

$slider-tabs-font-size:     (null: 16px,   $desktop: 14px,     $tablet: 14px,      $mobile: 14px );

// $h1-font-size:    (null: 60px,   $desktop: 60px,     $tablet: 50px,      $mobile: 40px );
// $h2-font-size:    (null: 46px,   $desktop: 46px,     $tablet: 40px,      $mobile: 36px );
// $h3-font-size:    (null: 30px,   $desktop: 30px,     $tablet: 30px,      $mobile: 30px );
// $h4-font-size:    (null: 30px,   $desktop: 30px,     $tablet: 30px,      $mobile: 30px );
// $h5-font-size:    (null: 24px,   $desktop: 24px,     $tablet: 24px,      $mobile: 24px );
// $h6-font-size:    (null: 16px,   $desktop: 16px,     $tablet: 14px,      $mobile: 14px );

// $hero-font-size:  (null: 116px,  $desktop: 116px,    $tablet: 80px,      $mobile: 58px );
// $meta-font-size:  (null: 12px,   $desktop: 12px,     $tablet: 12px,      $mobile: 12px );

html {
    line-height: 1.2;
    // @include font-size-map($body-font-size);
    font-size: 16px;
}

// classic headings...
@mixin type-h1 {
    @include font-size-map($alpha-font-size);
    line-height: 1.1;
    @include bold;
    font-family: $titleFont;
}

@mixin type-h2 {
    @include font-size-map($beta-font-size);
    line-height: 1.3;
    font-family: $titleFont;
    @include bold;
}

@mixin type-h3 {
    @include font-size-map($beta-font-size);
    line-height: 1.3;
    font-family: $titleFont;
    @include bold;
}

@mixin type-h4 {
    @include font-size-map($promo-copy-font-size);
    line-height: 1.3;
    font-family: $titleFont;
    // @include bold;
}

@mixin type-h5 {
    @include font-size-map($medium-font-size);
    line-height: 1.3;
    font-family: $titleFont;
}

@mixin type-h6 {
    @include font-size-map($medium-font-size);
    line-height: 1.3;
    font-family: $titleFont;
}

// set classses
h1, .type-h1 { @include type-h1; }
h2, .type-h2 { @include type-h2; }
h3, .type-h3 { @include type-h3; }
h4, .type-h4 { @include type-h4; }
h5, .type-h5 { @include type-h5; }
h6, .type-h6 { @include type-h6; }


// // named type styles..
// @mixin type-hero-heading {
//     @include font-size-map($h1-font-size);
//     line-height: 1.1;
//     @include bold;
//     font-family: $titleFont;
// }

// @mixin type-primary-heading {
//     @include font-size-map($h2-font-size);
//     line-height: 1.1;
//     @include bold;
//     font-family: $titleFont;
// }

// @mixin type-secondary-heading {
//     @include font-size-map($h2-font-size);
//     line-height: 1.1;
//     @include bold;
//     font-family: $titleFont;
// }

// @mixin type-sub-heading {
//     @include font-size-map($h2-font-size);
//     line-height: 1.1;
//     @include bold;
//     font-family: $titleFont;
// }

@mixin type-small-title {
    @include font-size-map($small-title-font-size);
    line-height: 1.4;
    // @include bold;
    font-family: $titleFont;
}

@mixin type-slider-tabs {
    @include font-size-map($slider-tabs-font-size);
    line-height: 1.1;
    // @include bold;
    font-family: $titleFont;
}

@mixin type-list-copy {
    @include font-size-map($list-font-size);
    line-height: 1.4;
    // @include bold;
    font-family: $bodyFont;
}

@mixin type-medium-copy {
    @include font-size-map($medium-font-size);
    line-height: 1.4;
    // @include bold;
    font-family: $bodyFont;
}

@mixin type-small-copy {
    @include font-size-map($small-font-size);
    line-height: 1.2;
    // @include bold;
    font-family: $bodyFont;
}

@mixin type-button {
    @include font-size-map($button-font-size);
    line-height: 1.1;
    font-family: $bodyFont;
}

@mixin type-promo-paragraph {
    @include font-size-map($promo-copy-font-size);
    line-height: 1.4;
    font-family: $bodyFont;
}

@mixin type-testimonial-paragraph {
    @include font-size-map($testimonial-copy-font-size);
    line-height: 1.4;
    font-family: $bodyFont;
}

// @mixin type-large-paragraph {
//     @include font-size-map($h5-font-size);
//     line-height: 1.1;
//     font-family: $bodyFont;
// }

// @mixin type-paragraph {
//     @include font-size-map($h6-font-size);
//     line-height: 1.1;
//     font-family: $bodyFont;
// }

// .type-hero-heading          { @include type-hero-heading; }
// .type-primary-heading       { @include type-primary-heading; }
// .type-secondary-heading     { @include type-secondary-heading; }
.type-slider-tabs               { @include type-slider-tabs; }
.type-small-title               { @include type-small-title; }
.type-list-copy                 { @include type-list-copy; }
.type-medium-copy               { @include type-medium-copy; }
.type-small-copy                { @include type-small-copy; }
.type-button                    { @include type-button; }
.type-promo-paragraph           { @include type-promo-paragraph; }
.type-testimonial-paragraph     { @include type-testimonial-paragraph; }
// .type-large-paragraph       { @include type-large-paragraph; }
// .type-paragraph             { @include type-paragraph; }



// Quotes.
blockquote {

  margin: $ss;

  p {
    border-left: 0.15rem solid $linkColour;
    padding-left: 1rem;

  }

  @include breakpoint(break-1) {
    margin-left: -1rem;
  }
}

// Horizontal rule.
hr {
  background-image: linear-gradient(to bottom,rgba(0, 0, 0, 0) 50%,$captionColour 50%);
  background-position: 0 50%;
  background-repeat: repeat-x;
  background-size: 100% 0.15rem;
  border: 0;
  margin: 0;
  padding-bottom: 3rem;
  padding-top: 3rem;
}

// Code block.
code, pre {
  background-color: $codeBackgroundColour;
  color: $codeColourColour;
}

pre {
  display: block;
  margin-bottom: 2rem;
  padding: 1rem;
  margin: $ss;
  white-space: pre;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
}

code {
  line-height: 1rem;
}

.main-content {

    ol,
    ul {

        margin-left: ($ss/1);
        margin-bottom: ($ss/2);

        li {
            margin-bottom: ($ss/2); 
            > ul,ol {
              margin-top: ($ss/2);   
            }
        }

    }

    // header,
    // .entry-content,
    // .entry-footer,
    // .share-bar,
    // .post-navigation,
    // .comments,
    // .post-comments {
    //     @include gel-wrap;
    // }

    // .entry-content--content {
    //     @include gel-layout;
    //     > * {
    //         @include gel-layout-item;
    //     }
    // }

    // // > * {
    // //     // @include gel-wrap;
    // // }

    // // @include gel-wrap;
    // // @include gel-layout;

    // article {
    //     // @include gel-layout-item;
    //     @include mq($from: tablet) {
    //         // padding: 0;
    //         // @include gel-columns(10/12);
    //         // margin-left: gel-columns(1/12);
    //     }
    //     @include mq($from: desktop) {
    //         // @include gel-columns(8/12);
    //         // margin-left: gel-columns(2/12);
    //     }
    // }
    
}
