// @include gel-wrap;
// @include gel-layout;
// @include gel-layout-item;
// @include gel-columns(6/12);
// @include mq($from: tablet) {
// @include mq($from: desktop) {
// @include mq($from: wide) {


/// LAYOUT STUFF
// .main-content {
//     .section-block {
//         // &--inner-wrap { @include gel-wrap; }
//         &--content-wrap { @include gel-layout; }
//     }
// }

.section-block:not(.homepage_hero):not(.usps):not(.statement_block):not(.testimonials) {

	// overflow-x: hidden;

	// padding: ($ss*7) 0;
	// padding: ($ss*2) 0;

	@include gel-wrap;
	
	&--inner-wrap {
		// @include gel-wrap;
	}

	&--content-wrap {
		// @include gel-layout;
		// z-index: 1;
		position: relative;
	}

	&--content {
		// @include gel-layout-item;
		margin: $ss 0; // ???
	}

	h1,h2,h3,h4,h5,h6 { margin-top: 0; }

	// &.hero_block {
	// 	// &--content-wrap {
	// }

}

.section-block.homepage_hero {

	position: relative;

}

// spacing between sections...
.section-block {
	&.testimonials,
	&.wysiwyg,
	&.usps {
		.section-block--content-wrap {		
			padding-top: 	 ($ss*4);
			padding-bottom:  ($ss*4);
		}
	}
}

// section-block colours....
.section-block {

	// &__blue {
	// 	background: $blue;
	// }
	&.testimonials,
	&.usps {
		background-color: $darkGrey;
		color: $white;
		h1,h2,h3,h4,h5,h6,p{
			color: $white;
		}

		.button {

			// color: $white;
			// background-color: $green;
			
			// &:hover { background-color: $red; }

			&_secondary {
				background-color: transparent;
				border-color: $white;
				color: $white;
				border: 2px solid $white;
				&:hover {
					background-color: $red;
					border-color: $red;
					color: $white;
				}
			}
		}

	}

}

/// gradient background...

.section-block {
	&.usps .usp_section__with_icons {
		background: radial-gradient(circle at 100% 50%, #32535b 0%, $darkGrey 30%);
		&:nth-child(odd) {
			background: radial-gradient(circle at 0% 50%, #32535b 0%, $darkGrey 30%);
		}
	}
	&.testimonials {
		background: radial-gradient(circle at 0% 50%, #32535b 0%, $darkGrey 20%);
	}
}