// @include gel-wrap;
// @include gel-layout;
// @include gel-layout-item;
// @include gel-columns(6/12);
// @include mq($from: tablet) {
// @include mq($from: desktop) {
// @include mq($from: wide) {


.homepage_hero {
	// position: relative;
	// .featured-image {
	// 	z-index: -1;
	// }
	// &--content {
	// 	// z-index: 2;
	// }

	// padding-top: ($ss*1);

	// .section-block--content {
		// margin-top: ($ss/1);
	// }

	overflow: hidden;

	.section-block--content-wrap {
		@include mq($from: desktop) {
			@include gel-wrap;
		}
		@include mq($from: wide) { max-width: 1720px !important; }
	}

	.section-block--content {
		@include mq($from: desktop) {
			@include gel-layout;
			// padding-top: 10vh;
		}
	}


	&--image {
		@include aspect-ratio(1,1);
		position: relative;
		// outline: 1px solid red;
		// > *, img {
		// position: absolute;
		// top: 0;
		// width: 100%;
		// }
		@include mq($from: desktop) {
			@include gel-columns(6/12);
		}

		&--grey-block {
			position: absolute;
			top: 0;
			width: 100%;
			left: -50%;
			z-index: -3;
		}

		&--white-fade {
			height: 100%;
			position: absolute;
			bottom: 0;
			z-index: -2;
			width: 100%;
			left: -50%;
			// background: ;
			background: rgb(255,255,255);
			background: linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 40%);
		}

		&--vertical-left-white-fade {
			height: 100%;
			position: absolute;
			bottom: 0;
			z-index: -2;
			width: 100%;
			left: -51%;
			// background: ;
			background: rgb(255,255,255);
			background: linear-gradient(90deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 40%);
		}

		&--second-grey-block {
			display: none;
			@include mq($from: desktop) {
				display: block;
				position: absolute;
				bottom: -30%;
				width: 100%;
				right: -100%;
				z-index: -3;
				svg {
					transform: rotate(180deg);
				}
			}
		}

		&--vertical-right-white-fade {
			display: none;
			@include mq($from: desktop) {
				display: block;
				height: 140%;
				position: absolute;
				bottom: -40%;
				z-index: -2;
				width: 100%;
				right: -100%;
				// background: ;
				background: rgb(255,255,255);
				background: linear-gradient(270deg, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 95%);
			}
		}

		&--image {
			// @include gel-wrap;

			position: absolute;
			@include center();
			// top: ($ss/2);
			max-width: 600px;
			max-height: 700px;
			width: 100%;
			padding: ($ss/2);
			z-index: -2;
			// left: -50%;

			img {
				min-width: 100%;
				// width: 100%;
			}
		
		}

		&--dots {

			position: absolute;
			width: 100%;
			height: 100%;
			// width: 100vw;
			// height: 100vw;
			top: 0;
			left: 0;
			overflow: hidden;
			z-index: -1;

			@include mq($from: desktop) {
				overflow: visible;
			}

			// top: -30%;
			// // top: 0;
			// left: -30%;
			// // left: 0;
			// width: 160%;
			// height: 160%;
			svg {
				// width: 160%;
				// height: 160%;
				// transform: translateX(-30%) translateY(-30%);
				position: absolute;
				// // left: 0;
				// // top: 0;

				top: -30%;
				// // top: 0;
				left: -30%;
				// // left: 0;
				width: 160%;
				height: 160%;
				@include mq($from: tablet) {

					top: -10%;
					// // top: 0;
					left: -10%;
					// // left: 0;
					width: 120%;
					height: 120%;

				}

				@include mq($from: desktop) {

					top: -20%;
					// // top: 0;
					left: -20%;
					// // left: 0;
					width: 140%;
					height: 140%;

				}
			}
			// overflow: hidden;
			// left: -50%;
		
		}

	}

	&--content {
		@include gel-wrap;

		@include mq($from: desktop) {
			@include gel-columns(6/12);
		}

	}

	&--content {
		margin: ($ss*1 0);
		text-align: center;
		position: relative;
		// z-index: 2;
		@include mq($from: desktop) {
			text-align: left;
			display: flex;
			flex-direction: column;
			// align-items: center;
			justify-content: center;
		}
		p {
			@include type-promo-paragraph;
			@include mq($from: tablet) {
				margin: ($ss/4) 0;
			}
		}
		a {
			display: inline-block;
			margin: ($ss*1 0);
			@include mq($from: desktop) {
				display: none;
			}
		}
	}

	.homepage_hero--desktop-cta {
		display: none;
		@include mq($from: desktop) {
			display: flex;
			justify-content: center;
			width: 100%;
			margin-top: ($ss*2);
		}
	}

}