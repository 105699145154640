body.admin-bar.logged-in {

}

body.admin-bar {
	.appearing-nav {
		top: 46px;
		@media (min-width: 48.9375em) {
			top: 32px;
		}
	}
}
