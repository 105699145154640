.button {
	padding: ($ss/4) ($ss*0.6);
	@include mq($from: desktop) {
		padding: ($ss/3) ($ss*1);
	}
	// margin: ($ss/1) 0;
	margin: 0 0 ($ss/2) 0;
	display: inline-block;
	color: $white;
	background-color: $green;
	border-radius: 4px;
	text-decoration: none;
	
	@include type-button;

	&:hover {
		background-color: $red;
	}
	&_secondary {
		background-color: transparent;
		border-color: $green;
		color: $darkGrey;
		border: 2px solid $green;
		&:hover {
			background-color: $red;
			border-color: $red;
			color: $white;
		}
	}
}