// @include gel-wrap;
// @include gel-layout;
// @include gel-layout-item;
// @include gel-columns(6/12);
// @include mq($from: tablet) {
// @include mq($from: desktop) {
// @include mq($from: wide) {

.site-footer {

	background-color: lighten($lightGrey, 10%);
	
	&--inner-wrapper {
		@include gel-wrap;
		text-align: center;
		margin: ($ss*6) auto ($ss*2);

		@include mq($from: desktop) {
			p {
				text-align: left;
				text-align: center;
				padding-top: ($ss);
			}
			.menu {
				text-align: left;
			}
			.site-footer--lower-section {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}

	}

	.social-links {

		display: flex;
		justify-content: center;

		.social-link {
			margin: ($ss*2) ($ss/3);
			a {
				// @include aspect-ratio(1,1);
				// width: 100%;
			}
			svg {
				width: 40px;
				height: 40px;
				path, circle, rect {
					fill: $darkGrey;
				}
			}
		}

	}

	.menu {
		margin: ($ss*2) 0;
		li {
			margin: ($ss*1) 0;
			a {
				text-decoration: none;
				color: $darkGrey;
			}
		}
	}

	a.footer_ccss_link {

		margin: ($ss*2) 0;
		display: inline-block;

		img {
			width: 100px;

		}
	}
}