// @include gel-wrap;
// @include gel-layout;
// @include gel-layout-item;
// @include gel-columns(6/12);
// @include mq($from: tablet) {
// @include mq($from: desktop) {
// @include mq($from: wide) {

.statement_block {

	min-height: 70vh;
	position: relative;

	svg {
		width: 22%;
		position: absolute;
		@include center(y); 
		@include mq($from: desktop) {
			width: 14%;
		}
	}

	.statement_block--content {

		@include gel-wrap;

		text-align: center;

		min-height: 70vh;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;

		@include mq($from: desktop) {
			max-width: 800px;
			justify-content: center;
			a {
				margin-top: ($ss*2);
			}
		}

	}
	
}