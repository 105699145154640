// @include gel-wrap;
// @include gel-layout;
// @include gel-layout-item;
// @include gel-columns(6/12);
// @include mq($from: tablet) {
// @include mq($from: desktop) {
// @include mq($from: wide) {

.usps {
	
	img {
		width: 30px;
	}

	.usps-large-section-heading {
		overflow: hidden;
		svg {
			position: relative;
			width: 200%;
			left: -50%;
			@include mq($from: tablet) {
				width: 140%;
				left: -20%;
			}
			@include mq($from: desktop) {
				width: 100%;
				left: 0;
				max-width: 1920px;
				margin: 0 auto;
				display: block;
			}
		}



		// &__discover {
		// }

	}

	.usps-icon-group {
		@include gel-wrap;

		&--icon-grid {
			display: flex;
			flex-wrap: wrap;
			// @include mq($from: desktop) {
			// 	flex-wrap: nowrap;
			// }

		}

		@include mq($from: desktop) {
			position: relative;
			top: -8vw;	
		}

	}

	.usps-icon-with-title {
		flex: 1 0 50%;
		@include mq($from: tablet) {
			flex: 1 0 33%;
			padding: ($ss/2) 0;
		}
		@include mq($from: desktop) {
			flex: 1 0 20%;
		}

		display: flex;
		flex-direction: column;
		align-items: center;
		// justify-content: center;
		img {
			width: 50px;
			flex: 1 0 auto;
			@include mq($from: tablet) {
				width: 80px;
			}
		}
		h6 {
			@include type-small-copy;
		}
	}

	.usps-icon-group--description {
		text-align: center;
		// margin: ($ss*2) 0;
		margin: ($ss*2);

		@include type-medium-copy;

	}

	.usp_section {

		&__usp_list,
		&__with_icons {

			// padding: ($ss*2) 0;

		}

		&__with_icons {

			padding: 0 0 ($ss*2) 0;

		}

		&__usp_list {

			padding: ($ss*2) 0;

			display: flex;
			flex-direction: column;
			align-items: center;

			text-align: center;

			h4 {
				 @include type-small-title;
			}

			img {
				width: 50px;
				@include mq($from: tablet) {
					width: 80px
				}
			}

			> * {
				margin: ($ss/2) 0;
			}

			ul {
				@include type-list-copy;
			}

		}

	}

	ul, li {
	    margin-left: 0;
	    margin-bottom: 0;
	}

}

.usp_section__ctas {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: ($ss/1) 0;
	@include mq($from: tablet) {
		flex-direction: row;
		justify-content: center;
		> * {
			margin: 0 ($ss/3);
		}
	}
}

