// AD Styles Developerbar

// @include gel-wrap;
// @include gel-layout;
// @include gel-layout-item;
// @include gel-columns(6/12);
// @include mq($from: tablet) {
// @include mq($from: desktop) {
// @include mq($from: wide) {

// $devbarHeight : 50px;

// body.has-devbar {
// 	padding-top: $devbarHeight;
// }

.devbar {
	position: fixed;
	top: 0;
	right: 5vw;
	background-color: #222;
	// border: 1px solid white;
	color: white;
	z-index: 1000000;
	padding: 0 1rem;
	border-radius: 0.5rem;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
	opacity: 0.1;
	transition: opacity 0.2s;
	&:hover {
		opacity: 1;
	}
	ul {
		display: flex;
	}
	li {
		margin-right: 1rem;
		&:last-of-type {
			margin-right: 0;
		}
	}
	a {
		text-decoration: none;
		color: white;
		// padding: 0 1rem;
		// background-color: #000;
		// padding: 1rem;
		display: block;
		// &.is-active,
		&:hover {
			// background-color: #555;
			color: orange;
			// text-decoration: underline;
		}
		&.is-active,
		&.is-active:hover {
			color: yellow;			
		}
	}
	* {
		outline: none !important;
	}
}

