// @include gel-wrap;
// @include gel-layout;
// @include gel-layout-item;
// @include gel-columns(6/12);
// @include mq($from: tablet) {
// @include mq($from: desktop) {
// @include mq($from: wide) {

.share-bar {
	&__mobile {
		display: block;
		@include mq($from: desktop) {
			display: none;
		}
	}
	&__desktop {
		display: none;
		@include mq($from: desktop) {
			// display: block;
			display: flex;
		}
		li {
			margin-right: ($ss/2);
			&:last-of-type {
				margin-right: 0;
			}
		}
		svg {
			width: 20px;
		}
	}
	
}